/**
 * File generated by js-routes 2.2.4
 * Based on Rails 6.1.7 routes of SmartFuneral::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /operators/customers/:customer_id/my_page/a4(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const a4_operators_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"a4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/my_page/a4(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const a4_users_customer_managements_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"a4"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_payouts/:id/accept(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_funerals_attendant_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_my_page_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_operator_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_remains_photo_staff_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id/add_cart(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_cart_funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/add(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_funerals_funeral_item_payouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/add(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_p_funeral_attendant_attendant_item_payouts_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_check_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_after_check_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_check_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_check_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_after_check_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_check_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_after_sale_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/announcements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_announcement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/announcements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_announcements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"announcements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_condolence_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_condolence_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_condolence_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_condolences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_crematoria_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_crematorium_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematorium_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_crematorium_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematorium_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/attendant_returned_items(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_attendant_returned_items_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_returned_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_memorials/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_memorials(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_funeral_memorials_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_movies/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_movies(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_funeral_movies_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_guide_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_guide_letter_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_letter_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funeral_services_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_greeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_greeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_greeting_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_greetings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_help_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_memorial_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_memorial_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_news_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_news_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_operator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/:operator_id/operated_organizations/:id(.:format)
 * @param {any} operator_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_operator_operated_organization_path = __jsr.r({"operator_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[3,"operator_id"],[2,[7,"/"],[2,[6,"operated_organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/:operator_id/operated_organizations(.:format)
 * @param {any} operator_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_operator_operated_organizations_path = __jsr.r({"operator_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[3,"operator_id"],[2,[7,"/"],[2,[6,"operated_organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_operators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/after_sale_items(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_after_sale_items_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"after_sale_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/allowed_ips/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_allowed_ip_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/allowed_ips(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_allowed_ips_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"allowed_ips"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/bank_accounts/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_bank_account_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/bank_accounts(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_bank_accounts_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/customers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_customers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/fax_settings(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_fax_settings_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"fax_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/freee_authentication(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_freee_authentication_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"freee_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/incense_fee(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_incense_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"incense_fee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/monthly_attendants(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_monthly_attendants_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_attendants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/monthly_funerals(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_monthly_funerals_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/monthly_sales(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_monthly_sales_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/organizations/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_organization_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/organization_products(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_organization_products_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/organizations(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_organizations_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/payment_setting(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_payment_setting_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"payment_setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:product_id/downloads(.:format)
 * @param {any} organization_id
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_downloads_path = __jsr.r({"organization_id":{"r":true},"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:product_id/product_images/:id(.:format)
 * @param {any} organization_id
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_product_image_path = __jsr.r({"organization_id":{"r":true},"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:product_id/product_images(.:format)
 * @param {any} organization_id
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_product_images_path = __jsr.r({"organization_id":{"r":true},"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/product_purchase_orders/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/product_purchase_orders(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_product_purchase_orders_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_products_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/purchase_orders/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/purchase_orders(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_purchase_orders_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/returned_item_purchase_orders/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_returned_item_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/returned_item_purchase_orders(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_returned_item_purchase_orders_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/select_banks(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_select_banks_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"select_banks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/send_purchase_notices/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_send_purchase_notice_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"send_purchase_notices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/send_purchase_notices(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_send_purchase_notices_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"send_purchase_notices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/service_usage_fees/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/service_usage_fees(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_service_usage_fees_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/shipping_fee(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/stripe(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_stripe_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"stripe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/suppliers/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_supplier_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/suppliers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_suppliers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organization_usages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_usages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organization_usages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users/:user_id/organization_users/:id(.:format)
 * @param {any} organization_id
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_user_organization_user_path = __jsr.r({"organization_id":{"r":true},"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"organization_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users/:user_id/organization_users(.:format)
 * @param {any} organization_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_user_organization_users_path = __jsr.r({"organization_id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"organization_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_users_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/venues/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_venue_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/venues(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organization_venues_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"venues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_product_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_remains_photo_costume_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_remains_photo_costumes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_staffs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_remains_photo_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_staffs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_remains_photo_staffs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_staffs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admins/select_bank_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_select_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"select_bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/select_venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_select_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"select_venues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admins/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard/after_check_completed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const after_check_completed_my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"after_check_completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/areas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_areas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/blands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_blands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"blands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/catalogs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_catalogs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"catalogs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/customers/:customer_id/estimates/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_customer_estimate_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/customers/:customer_id/estimates(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_customer_estimates_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"estimates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/estimates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_estimates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"estimates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/product_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_product_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/product_patterns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_product_patterns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_patterns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/product_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_product_sets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"product_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/rough_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_rough_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"rough_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/tablet_product_menus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_tablet_product_menus_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/mobile/v1/user/session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_v1_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/areas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_areas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/inquiries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_inquiries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"inquiries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/omotenashi_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_omotenashi_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"omotenashi_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/osonae_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_osonae_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"osonae_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/self_planning_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_self_planning_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"self_planning_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/remains_photos/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_remains_photo_staffs_remains_photo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:id/auth(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_p_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/freee_authentication/authorize(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authorize_admins_organization_freee_authentication_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"freee_authentication"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/available_dm_send_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_dm_send_list_operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"available_dm_send_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/available_dm_send_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_dm_send_list_users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"available_dm_send_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/available_mail_magazine_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_mail_magazine_list_operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"available_mail_magazine_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/available_mail_magazine_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_mail_magazine_list_users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"available_mail_magazine_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/balance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const balance_users_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/:id/bank_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_funerals_funeral_item_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id/bank_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts/:id/bank_order(.:format)
 * @param {any} membership_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_membership_customer_customer_payout_path = __jsr.r({"membership_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:id/bank_order(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/:id/bank_order(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_p_funeral_attendant_attendant_item_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:id/bank_order(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_order_p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bank_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:sms_log_id/sms_log_customers/batch_create(.:format)
 * @param {any} sms_log_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_create_operators_sms_log_sms_log_customers_path = __jsr.r({"sms_log_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[2,[7,"/"],[2,[6,"batch_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:sms_log_id/sms_log_customers/batch_create(.:format)
 * @param {any} sms_log_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_create_users_customer_managements_sms_log_sms_log_customers_path = __jsr.r({"sms_log_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[2,[7,"/"],[2,[6,"batch_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/batch_lock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_lock_users_customer_managements_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"batch_lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_path = __jsr.r({}, [2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/run(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_cancel_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_tables_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"tables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/schema(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_schema_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"schema"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_docs_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_query_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/queries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/checks/:id/run(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_checks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"checks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/checks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_check_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/checks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/checks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/dashboards/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/dashboards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboards_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/dashboards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_dashboard_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/dashboards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/dashboards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ilv2qd4hvpowq1p6asqq/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"ilv2qd4hvpowq1p6asqq"]],[7,"/"]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/freee_authentication/callback(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const callback_admins_organization_freee_authentication_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"freee_authentication"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_my_page_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_operator_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_remains_photo_staff_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_users_customer_managements_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/cancel(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:id/cash_order(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cash_order_p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cash_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/dashboard/charts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charts_users_customer_managements_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /commercial_transaction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commercial_transaction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"commercial_transaction"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:id/complete(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_operators_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:id/complete(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_users_customer_managements_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard/completed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completed_my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard/confirm_contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_contact_my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"confirm_contact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/confirm_orders(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_orders_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard/contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_operators_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_manages_bland_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_manages_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_customer_managements_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_users_product_pattern_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/create_cnv(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_cnv_users_customer_managements_invoice_invoice_e_scott_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"create_cnv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/create_defaults(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_defaults_users_customer_managements_manages_inflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[6,"create_defaults"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/csv_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csv_downloads_users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"csv_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/invoices/csv(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csv_users_customer_managements_customer_customer_funeral_target_invoices_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_after_sale_partners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_after_sale_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_after_sale_partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_funeral_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_my_page_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_operator_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/destroy_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_order_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_remains_photo_staff_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/boards/digital_board(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const digital_board_users_customer_managements_boards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"boards"],[2,[7,"/"],[2,[6,"digital_board"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/tablets/estimates/direct(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const direct_operators_customer_tablets_estimates_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"direct"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/tablets/estimates/direct(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const direct_users_customer_managements_customer_tablets_estimates_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"direct"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_condolence_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_condolence_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_crematorium_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_memorials/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_movies/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_funeral_guide_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_funeral_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_greeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_greeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_memorial_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_news_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:id/down(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_organization_product_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:product_id/product_images/:id/down(.:format)
 * @param {any} organization_id
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_organization_product_product_image_path = __jsr.r({"organization_id":{"r":true},"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_admins_remains_photo_costume_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/funeral_memorials/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_funerals_dashboard_funeral_memorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_funerals_dashboard_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:customer_funeral_target_invoice_id/customer_funeral_target_invoice_invoices/:id/down(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} customer_funeral_target_invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_customer_funeral_target_invoice_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"customer_funeral_target_invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"customer_funeral_target_invoice_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoice_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_hibiya_hibiya_enforcer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_hibiya_hibiya_mail_recipient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_inquiry_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_after_sale_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_area_for_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_billing_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_billing_transfer_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_billing_update_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_bland_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_boarding_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_car_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_catalog_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_chartered_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_customer_comment_tag_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_customer_comment_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_customer_funeral_target_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_customer_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_deposit_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_deposit_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_escalation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_estimate_confirmation_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_estimate_title_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_floral_tribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_floral_tribute_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_florist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_hope_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_hope_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_scale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_funeral_target_manager_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_inquiry_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_inquiry_order_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_invoice_additional_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_lounge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_mail_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_media_trigger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_meeting_behavior_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_meeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_member_benefit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_member_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_member_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_member_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_order_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/down(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_payment_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_person_in_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_phase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_pick_up_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_images/:id/down(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_product_set_product_set_image_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_products/:id/down(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_product_set_product_set_product_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_selects/:id/down(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_product_set_product_set_select_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_selects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_select_products/:id/down(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_product_set_product_set_select_product_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_select_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_proviso_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_purchase_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_purify_manner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_purify_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_purpose_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_rough_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_rough_product_osonae_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_sale_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_sending_funeral_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_sms_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_tablet_product_menu_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:tablet_product_menu_id/tablet_product_menu_products/:id/down(.:format)
 * @param {any} tablet_product_menu_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_tablet_product_menu_tablet_product_menu_product_path = __jsr.r({"tablet_product_menu_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"tablet_product_menu_id"],[2,[7,"/"],[2,[6,"tablet_product_menu_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_transport_destination_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_manages_transport_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/down(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_customer_managements_self_planning_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_discounted_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_memorials/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_movies/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services/:id/down(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_bundle_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children/:id/down(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_kind_child_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/products/:id/down(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_kind_product_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_pattern_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_images/:id/down(.:format)
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_product_product_image_path = __jsr.r({"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_remark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venues/:id/down(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const down_users_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"down"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/tes/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_users_te_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"tes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /e_scotts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const e_scott_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"e_scotts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/e_scott(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const e_scott_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"e_scott"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_check_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_after_check_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_check_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/announcements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_announcement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_condolence_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_condolence_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_crematorium_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_movies/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_funeral_guide_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_funeral_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_greeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_greeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_memorial_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_news_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_operator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/allowed_ips/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_allowed_ip_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/bank_accounts/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_bank_account_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/freee_authentication/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_freee_authentication_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"freee_authentication"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/incense_fee/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_incense_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"incense_fee"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/organizations/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_organization_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/payment_setting/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_payment_setting_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"payment_setting"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_product_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/product_purchase_orders/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_product_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/purchase_orders/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/returned_item_purchase_orders/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_returned_item_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/service_usage_fees/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/shipping_fee/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/suppliers/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_supplier_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/venues/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_organization_venue_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_remains_photo_costume_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_staffs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_remains_photo_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admins_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_funerals_attendant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_payouts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_funerals_attendant_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_funerals_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_funerals_dashboard_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:id/edit_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_invites_users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:id/edit_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_invites_users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:id/edit_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_invites_users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:id/edit_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_invites_users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_my_page_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_my_page_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/edit(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_o_attendant_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/account/edit(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_o_attendant_account_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:id/edit(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/attendant_ec_info/edit(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_o_attendant_attendant_payout_attendant_ec_info_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"attendant_ec_info"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/reset_passwords/:id/edit(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_o_attendant_reset_password_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"reset_passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operator_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operator_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_linkages/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_member_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:member_id/member_payments/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/my_page/edit(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/meetings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organization_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_organization_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_after_sale_item/edit(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_partner_partner_after_sale_item_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_after_sale_item"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_staffs/:id/edit(.:format)
 * @param {any} partner_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/rota/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_rotum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/schedules/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_operators_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /organization/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organization"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /organizations/organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_organizations_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/edit(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_p_funeral_attendant_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/account/edit(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_p_funeral_attendant_account_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant_reset_passwords/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_p_funeral_attendant_reset_password_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_reset_passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staff/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_remains_photo_staff_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staff"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_remains_photo_staff_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_remains_photo_staff_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/edit_suppliers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_suppliers_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/allowed_ips/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_allowed_ip_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/bank_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledgers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_accounts_general_ledger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledgers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledger_relationships/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_accounts_general_ledger_relationship_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledger_relationships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/subsidiary_ledgers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_accounts_subsidiary_ledger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"subsidiary_ledgers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/batch_deposit_locks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_batch_deposit_lock_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"batch_deposit_locks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bellmony/membership_card_summary_details/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_bellmony_membership_card_summary_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"membership_card_summary_details"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/boards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_board_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"boards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/time_schedule/edit(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_funeral_target_time_schedule_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"time_schedule"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_linkages/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/edit(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_ossuaries_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/cremated_remains/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_customer_ossuaries_cremated_remain_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"cremated_remains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_duplications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_duplication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_duplications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_member_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:member_id/member_payments/:id/edit(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/my_page/edit(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries/direct_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_direct_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[6,"direct_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/cm_sign_dossier/edit(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_estimate_cm_sign_dossier_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"cm_sign_dossier"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/hakkoden_actual_stocks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hakkoden_hakkoden_actual_stock_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"hakkoden_actual_stocks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_adjustments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hakkoden_order_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_adjustments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_to_supplier_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hakkoden_order_to_supplier_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_to_supplier_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hibiya_hibiya_enforcer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hibiya_hibiya_expense_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hibiya_hibiya_mail_recipient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/sales/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_hibiya_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_inquiry_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/invoice_e_scott_mails/:id/edit(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_invoice_invoice_e_scott_invoice_e_scott_mail_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"invoice_e_scott_mails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_payments/:id/edit(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_invoice_invoice_payment_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts/:id/edit(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_invoice_invoice_receipt_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_summaries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_invoice_summary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_summaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_after_sale_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_area_for_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_area_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/at_temples/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_at_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"at_temples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_billing_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_billing_transfer_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_billing_update_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_bland_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_boarding_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_car_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_catalog_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_chartered_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/coffins/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_coffin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"coffins"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/crematorium_goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_crematorium_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"crematorium_goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_customer_comment_tag_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_customer_comment_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_customer_funeral_target_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_customer_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_deposit_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_deposit_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/e_scott_settings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_e_scott_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"e_scott_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_escalation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_estimate_confirmation_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_estimate_title_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_floral_tribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_floral_tribute_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_florist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_hope_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_hope_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_scale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_funeral_target_manager_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflow_goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_inflow_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflow_goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_inquiry_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_inquiry_order_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_invoice_additional_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/life_plan_codes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_life_plan_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"life_plan_codes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_lounge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_mail_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_media_trigger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_meeting_behavior_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_meeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_member_benefit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_member_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_member_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_member_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/notice_setting_funerals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_notice_setting_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"notice_setting_funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_order_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_organization_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_item_actions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_organization_after_sale_item_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_item_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/edit(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_organization_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_payment_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_person_in_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_phase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_pick_up_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_product_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_proviso_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_purchase_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_purify_manner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_purify_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_purpose_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_rough_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_rough_product_osonae_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_sale_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_goals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_sale_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_goals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_sending_funeral_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/shonanokas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_shonanoka_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"shonanokas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_auto_sents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_sms_auto_sent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_sms_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_tablet_product_menu_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/talk_manuals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_talk_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"talk_manuals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/time_schedule_masters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_time_schedule_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"time_schedule_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_transport_destination_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_transport_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/waiting_rooms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_waiting_room_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/yukans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_manages_yukan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"yukans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/meetings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_payments/:id/edit(.:format)
 * @param {any} order_to_supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_order_to_supplier_order_to_supplier_payment_path = __jsr.r({"order_to_supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/edit(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_organization_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_after_sale_item/edit(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_partner_partner_after_sale_item_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_after_sale_item"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_staffs/:id/edit(.:format)
 * @param {any} partner_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_repayment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rota/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_rotum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/schedules/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_self_planning_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_auto_sents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_sms_auto_sent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_customer_managements_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_discounted_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_letter_prints/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_funeral_funeral_letter_print_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_letter_prints"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_movies/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/remains_photos/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_funeral_remains_photo_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services/:id/edit(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organizations/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_organization_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_roles/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_organization_role_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/service_usage_fees/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/shipping_fee/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users/:id/edit(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/y_osohshiki_setting/edit(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_organization_y_osohshiki_setting_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"y_osohshiki_setting"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_bundle_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children/:id/edit(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_kind_child_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_pattern_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_purchase_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_product_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/purchase_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_remark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/returned_item_purchase_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_returned_item_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/supplier_imports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_supplier_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"supplier_imports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venues/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /estimates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exports_users_customer_managements_repayments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/fetch_cnv_result(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_cnv_result_users_customer_managements_invoice_invoice_e_scott_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"fetch_cnv_result"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:id/fetch_signed(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_signed_users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_signed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:id/fetch_signed(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_signed_users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_signed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:id/fetch_signed(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_signed_users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_signed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:id/fetch_signed(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fetch_signed_users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fetch_signed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /call/finish_incoming(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finish_incoming_call_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"call"],[2,[7,"/"],[2,[6,"finish_incoming"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/freee_deal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freee_deal_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"freee_deal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/direct_orders/:id/freee_last_error_message(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freee_last_error_message_users_customer_managements_direct_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"direct_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"freee_last_error_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/freee_last_error_message(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freee_last_error_message_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"freee_last_error_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/freee_last_error_message(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freee_last_error_message_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"freee_last_error_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funeral_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/funeral(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funeral_users_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"funeral"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants/:attendant_id/attendant_messages(.:format)
 * @param {any} attendant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_attendant_messages_path = __jsr.r({"attendant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[3,"attendant_id"],[2,[7,"/"],[2,[6,"attendant_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants/:attendant_id/enroll(.:format)
 * @param {any} attendant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_enroll_path = __jsr.r({"attendant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[3,"attendant_id"],[2,[7,"/"],[2,[6,"enroll"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_payouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_payouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_payouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendant_photos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_attendants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/funeral_memorials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_funeral_memorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/funeral_memorials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_funeral_memorials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"funeral_memorials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/funeral_movies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_funeral_movies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"funeral_movies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_dashboard_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_item_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_item_payouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:funeral_payout_id/attendants/:id(.:format)
 * @param {any} funeral_payout_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_attendant_path = __jsr.r({"funeral_payout_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"funeral_payout_id"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:funeral_payout_id/attendant_returned_items/:id(.:format)
 * @param {any} funeral_payout_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_attendant_returned_item_path = __jsr.r({"funeral_payout_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"funeral_payout_id"],[2,[7,"/"],[2,[6,"attendant_returned_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:funeral_payout_id/attendants(.:format)
 * @param {any} funeral_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_attendants_path = __jsr.r({"funeral_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"funeral_payout_id"],[2,[7,"/"],[2,[6,"attendants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:funeral_payout_id/products/:id(.:format)
 * @param {any} funeral_payout_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_product_path = __jsr.r({"funeral_payout_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"funeral_payout_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:funeral_payout_id/products(.:format)
 * @param {any} funeral_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_funeral_payout_products_path = __jsr.r({"funeral_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"funeral_payout_id"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/greeting_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_greeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/greeting_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_greeting_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"greeting_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/remains_photos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_remains_photo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/remains_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_remains_photos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"remains_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /funerals/select_venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const funerals_select_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"select_venues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /gmo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gmo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gmo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /health-check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"health-check"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/dashboard/hide_calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hide_calendar_users_customer_managements_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"hide_calendar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/hold(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hold_users_customer_managements_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"hold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/oarks/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_users_oarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"oarks"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /call/incoming(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_call_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"call"],[2,[7,"/"],[2,[6,"incoming"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/incoming_call(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_call_operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"incoming_call"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/incoming_call(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_call_users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"incoming_call"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /call/incoming_status_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_status_callback_call_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"call"],[2,[7,"/"],[2,[6,"incoming_status_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:id/incomplete(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incomplete_operators_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"incomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:id/incomplete(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incomplete_users_customer_managements_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"incomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/information(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const information_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/information(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const information_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /kaiwa_cloud/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kaiwa_cloud_webhook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"kaiwa_cloud"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/kumotsu(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kumotsu_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"kumotsu"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/kyouka(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kyouka_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"kyouka"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/letter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_admins_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"letter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/batch_deposit_locks/lock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_users_customer_managements_batch_deposit_locks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"batch_deposit_locks"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:id/lock(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts/:id/lock(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_users_customer_managements_invoice_invoice_receipt_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/:id/lock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_users_customer_managements_repayment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users/:id/lock(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_users_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts/:id(.:format)
 * @param {any} membership_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const membership_customer_customer_payout_path = __jsr.r({"membership_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts(.:format)
 * @param {any} membership_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const membership_customer_customer_payouts_path = __jsr.r({"membership_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses/month(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const month_users_customer_managements_hibiya_hibiya_expenses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[2,[7,"/"],[2,[6,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_page_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_page_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_page_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_pages/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_page_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_pages_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/directs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_pages_direct_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"directs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_pages_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize/native(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const native_oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"native"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_check_items/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_after_check_item_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_check_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_after_sale_item_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/announcements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_announcement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_condolence_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_condolence_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_crematorium_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematorium_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_crematorium_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematorium_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_funeral_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_funeral_guide_letter_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_funeral_letter_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_greeting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_greeting_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_help_video_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_memorial_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_news_item_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_operator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/after_sale_items/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_after_sale_item_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/allowed_ips/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_allowed_ip_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/bank_accounts/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_bank_account_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/organizations/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_organization_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_product_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/product_purchase_orders/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_product_purchase_order_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/purchase_orders/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_purchase_order_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/returned_item_purchase_orders/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_returned_item_purchase_order_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/service_usage_fees/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/shipping_fee/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/suppliers/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_supplier_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/users/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_user_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/venues/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_organization_venue_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_product_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_remains_photo_costume_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_staffs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_remains_photo_staff_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admins_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/new_cnv(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cnv_users_customer_managements_invoice_invoice_e_scott_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"new_cnv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funeral_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_attendant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendants"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/attendant_payouts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_attendant_payout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_dashboard_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_funeral_item_payout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_funeral_payout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/remains_photos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_funerals_remains_photo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts/new(.:format)
 * @param {any} membership_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_membership_customer_customer_payout_path = __jsr.r({"membership_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_my_page_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_my_page_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_my_page_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_my_page_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/attendant_ec_info/new(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_attendant_payout_attendant_ec_info_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"attendant_ec_info"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/gmo/new(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_attendant_payout_gmo_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"gmo"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/incense/new(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_attendant_payout_incense_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_photos/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_attendant_photo_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/messages/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_message_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/reset_passwords/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_reset_password_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"reset_passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/sessions/new(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_o_attendant_session_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_oauth_application_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/business_report/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_business_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"business_report"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_files/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_file_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_linkages/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/customer_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/invite/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_invite_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_member_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:member_id/member_payments/new(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/my_page/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/send_smss/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_send_smss_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_smss"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/tablets/estimates/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_customer_tablets_estimates_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_inquiry_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/meetings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_meeting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organization_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_organization_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_partner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_staffs/new(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/rota/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_rotum_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/schedules/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operators_sms_log_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /organizations/:organization_id/stripe_connects/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_organization_stripe_connect_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"stripe_connects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /organizations/organizations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_organizations_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_item_payout_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_messages/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_message_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:attendant_payout_id/gmo/new(.:format)
 * @param {any} funeral_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_payout_gmo_path = __jsr.r({"funeral_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"gmo"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:attendant_payout_id/incense/new(.:format)
 * @param {any} funeral_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_payout_incense_path = __jsr.r({"funeral_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_photos/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_attendant_photo_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant_reset_passwords/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_reset_password_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_reset_passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/sessions/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_p_funeral_attendant_session_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /plannings/:planning_id/registers/new(.:format)
 * @param {any} planning_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_planning_register_path = __jsr.r({"planning_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plannings"],[2,[7,"/"],[2,[3,"planning_id"],[2,[7,"/"],[2,[6,"registers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_remains_photo_staff_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_remains_photo_staff_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_remains_photo_staff_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_remains_photo_staff_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_remains_photo_staff_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/allowed_ips/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_allowed_ip_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/bank_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledgers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_accounts_general_ledger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledgers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledger_relationships/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_accounts_general_ledger_relationship_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledger_relationships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/subsidiary_ledgers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_accounts_subsidiary_ledger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"subsidiary_ledgers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/applicant_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_applicant_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"applicant_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/batch_deposit_locks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_batch_deposit_lock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"batch_deposit_locks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bellmony/membership_card_summary_details/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_bellmony_membership_card_summary_detail_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"membership_card_summary_details"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/boards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_board_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"boards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bulk_register_invoice/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_bulk_register_invoice_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bulk_register_invoice"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_files/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_file_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_files/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_file_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/time_schedule/new(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_funeral_target_time_schedule_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"time_schedule"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_linkages/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_ossuaries_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/cremated_remains/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_customer_ossuaries_cremated_remain_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"cremated_remains"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_funeral_targets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_funeral_target_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/customer_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/invite/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_invite_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_member_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:member_id/member_payments/new(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/my_page/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/send_smss/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_send_smss_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_smss"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/tablets/estimates/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_customer_tablets_estimates_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries/direct_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_direct_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[6,"direct_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_estimate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/cm_sign_dossier/new(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_estimate_cm_sign_dossier_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"cm_sign_dossier"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/sign/new(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_estimate_sign_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"sign"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/hakkoden_actual_stocks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hakkoden_hakkoden_actual_stock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"hakkoden_actual_stocks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_adjustments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hakkoden_order_adjustment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_adjustments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_to_supplier_payments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hakkoden_order_to_supplier_payment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hibiya_hibiya_enforcer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hibiya_hibiya_expense_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hibiya_hibiya_mail_recipient_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/sales/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_hibiya_sale_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_inquiry_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_inquiry_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_inquiry_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/invoice_e_scott_mails/new(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_invoice_e_scott_invoice_e_scott_mail_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"invoice_e_scott_mails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_payments/new(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_invoice_payment_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts/new(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_invoice_receipt_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_summaries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_invoice_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_summaries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_after_sale_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_area_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_area_for_my_temple_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/at_temples/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_at_temple_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"at_temples"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_billing_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_billing_transfer_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_billing_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_bland_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_boarding_place_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_car_model_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_catalog_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_chartered_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/coffins/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_coffin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"coffins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_customer_comment_tag_master_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_customer_comment_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_customer_funeral_target_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_customer_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_deposit_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_deposit_place_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/e_scott_settings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_e_scott_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"e_scott_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_emergency_contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_escalation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_estimate_confirmation_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_estimate_title_master_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_floral_tribute_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_floral_tribute_style_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_florist_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_hope_area_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_hope_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_scale_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_style_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_funeral_target_manager_master_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_inflow_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_inquiry_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_inquiry_order_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_invoice_additional_content_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/life_plan_codes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_life_plan_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"life_plan_codes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_lounge_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_mail_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_manual_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_media_trigger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_meeting_behavior_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_meeting_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_member_benefit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_member_inflow_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_member_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_member_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_membership_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_my_temple_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples/my_temple_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_my_temple_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[2,[7,"/"],[2,[6,"my_temple_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/notice_setting_funerals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_notice_setting_funeral_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"notice_setting_funerals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_order_inflow_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_item_actions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_organization_after_sale_item_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_item_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_organization_attribute_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/new(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_organization_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_payment_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_person_in_charge_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_phase_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_pick_up_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_product_set_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stock_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_product_stock_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stock_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_proviso_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_purchase_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_purify_manner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_purify_place_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_purpose_master_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_rough_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_rough_product_osonae_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_sale_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_sending_funeral_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/shonanokas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_shonanoka_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"shonanokas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_auto_sents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_sms_auto_sent_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_sms_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_tablet_product_menu_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/talk_manuals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_talk_manual_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"talk_manuals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/time_schedule_masters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_time_schedule_master_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"time_schedule_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_transport_destination_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_transport_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/waiting_rooms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_waiting_room_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/yukans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_manages_yukan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"yukans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/meetings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_meeting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_order_to_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_supplier_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_order_to_supplier_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_supplier_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_payments/new(.:format)
 * @param {any} order_to_supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_order_to_supplier_order_to_supplier_payment_path = __jsr.r({"order_to_supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_organization_attribute_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/new(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_organization_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_partner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_staffs/new(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_receipt_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_repayment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rota/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_rotum_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rotum_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_rotum_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rotum_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/schedules/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_self_planning_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_simple_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customer_funeral_targets/:customer_funeral_target_id/estimates/new(.:format)
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_simple_customer_funeral_target_estimate_path = __jsr.r({"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_auto_sents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_sms_auto_sent_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_customer_managements_sms_log_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_discounted_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_product_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_discounted_product_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_product_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_letter_prints/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_funeral_letter_print_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_letter_prints"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_signages/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_funeral_signage_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_signages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/invite/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_invite_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"invite"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/remains_photos/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_remains_photo_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services/new(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organizations/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_organization_organization_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_roles/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_organization_organization_role_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/service_usage_fees/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/shipping_fee/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users/new(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_organization_user_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/otp/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/payments/:payment_id/condolence/new(.:format)
 * @param {any} payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_payment_condolence_path = __jsr.r({"payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"payment_id"],[2,[7,"/"],[2,[6,"condolence"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_bundle_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_image_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_image_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_image_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_kind_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children/new(.:format)
 * @param {any} product_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_kind_child_path = __jsr.r({"product_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_pattern_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_purchase_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_product_purchase_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/purchase_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_purchase_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_remark_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/returned_item_purchase_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_returned_item_purchase_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_supplier_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/supplier_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_supplier_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"supplier_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venues/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_venue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venue_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_users_venue_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venue_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/account(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_account_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:id(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/attendant_ec_info(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_payout_attendant_ec_info_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"attendant_ec_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/confirmation(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_payout_confirmation_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/incense(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_payout_incense_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_payouts_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_photos(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_attendant_photos_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/messages(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_messages_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/reset_passwords/:id(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_reset_password_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"reset_passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/reset_passwords(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_reset_passwords_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"reset_passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/sessions/:id(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_session_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/sessions(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_attendant_sessions_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/funerals(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_funerals_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/obituaries(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_obituaries_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"obituaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/orders(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_orders_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/products/:id(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_product_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/search_venues/:id(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_search_venue_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"search_venues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/search_venues(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_search_venues_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"search_venues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/send_incense(.:format)
 * @param {any} o_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const o_send_incense_path = __jsr.r({"o_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"send_incense"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oarks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /oauth/applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_application_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/authorized_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_authorized_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"authorized_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_introspect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_revoke_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_token_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /online(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const online_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"online"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operators/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operators/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/business_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_business_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"business_report"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sale_customer_after_sale_actions_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_partners/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sale_customer_after_sale_partner_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_partners(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sale_customer_after_sale_partners_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_partners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_after_sales(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_after_sales_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_comments/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_comment_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_comments(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_comments_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_files/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_file_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_files(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_files_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_target_customer_funeral_target_managers_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_target_customer_funeral_target_receipts_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_funeral_targets_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_linkages/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_linkages(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_linkages_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_meetings/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_meeting_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_meetings/:customer_meeting_id/customer_meeting_behaviors/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_meeting_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_meeting_customer_meeting_behavior_path = __jsr.r({"customer_id":{"r":true},"customer_meeting_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"customer_meeting_id"],[2,[7,"/"],[2,[6,"customer_meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_meetings/:customer_meeting_id/customer_meeting_behaviors(.:format)
 * @param {any} customer_id
 * @param {any} customer_meeting_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_meeting_customer_meeting_behaviors_path = __jsr.r({"customer_id":{"r":true},"customer_meeting_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"customer_meeting_id"],[2,[7,"/"],[2,[6,"customer_meeting_behaviors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_meetings(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_meetings_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_users(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_customer_users_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/customer_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/invite(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_invite_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_member_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:member_id/member_payments/:id(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members/:member_id/member_payments(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_member_member_payments_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/members(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_members_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/my_page(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/send_smss(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_send_smss_index_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_smss"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/sms_log_customers(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customer_sms_log_customers_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/help_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/help_videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_help_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"help_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_inquiries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:inquiry_id/customers/:id(.:format)
 * @param {any} inquiry_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_inquiry_customer_path = __jsr.r({"inquiry_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:inquiry_id/customers/customer_comments(.:format)
 * @param {any} inquiry_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_inquiry_customer_comments_path = __jsr.r({"inquiry_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:inquiry_id/customers(.:format)
 * @param {any} inquiry_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_inquiry_customers_path = __jsr.r({"inquiry_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/kaiwa_cloud_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_kaiwa_cloud_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"kaiwa_cloud_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/latest_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_latest_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"latest_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/meetings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/meetings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_meetings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"meetings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/notices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"notices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/customer_funeral_targets/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_customer_funeral_target_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/customer_funeral_targets(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_customer_funeral_targets_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organization_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organization_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organization_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_customers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_customers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_funerals/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_funeral_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_funerals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_funerals(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_funerals_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_pick_up_locations(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_pick_up_locations_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_pick_up_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_product_sets/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_product_set_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_product_sets(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_product_sets_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_product_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_products(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_products_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organizations/:organization_id/selected_purpose_masters(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_organization_selected_purpose_masters_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"selected_purpose_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_after_sale_item(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_partner_partner_after_sale_item_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_after_sale_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_staffs/:id(.:format)
 * @param {any} partner_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/:partner_id/partner_staffs(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_partner_partner_staffs_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_partners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operators/rota(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_rota_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"rota"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/rota/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_rotum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operators/selected_organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_selected_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"selected_organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:sms_log_id/sms_log_customers/:id(.:format)
 * @param {any} sms_log_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_sms_log_sms_log_customer_path = __jsr.r({"sms_log_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:sms_log_id/sms_log_customers(.:format)
 * @param {any} sms_log_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_sms_log_sms_log_customers_path = __jsr.r({"sms_log_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operators_sms_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /order_to_suppliers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /order_to_suppliers/:order_to_supplier_id/order_to_supplier_comments/:id(.:format)
 * @param {any} order_to_supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_to_supplier_order_to_supplier_comment_path = __jsr.r({"order_to_supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /order_to_suppliers/:order_to_supplier_id/order_to_supplier_comments(.:format)
 * @param {any} order_to_supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_to_supplier_order_to_supplier_comments_path = __jsr.r({"order_to_supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organization"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /organizations/:organization_id/stripe_connects(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_stripe_connects_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"stripe_connects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_users_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /organizations/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organizations_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /organizations/organization_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organizations_organization_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"organization_products"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /organizations/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organizations_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /organizations/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organizations_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /call/outgoing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const outgoing_call_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"call"],[2,[7,"/"],[2,[6,"outgoing"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/account(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_account_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_item_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_item_payouts_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_messages(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_messages_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:attendant_payout_id/incense(.:format)
 * @param {any} funeral_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_payout_incense_path = __jsr.r({"funeral_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_payouts_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_photos(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_attendant_photos_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/qr(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_qr_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"qr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant_reset_passwords/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_reset_password_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_reset_passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant_reset_passwords(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_reset_passwords_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_reset_passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/sessions/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_session_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/sessions(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_attendant_sessions_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/carts/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_cart_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/carts(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_carts_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"carts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/products/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_product_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/send_incenses/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_send_incense_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"send_incenses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/send_incenses(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const p_funeral_send_incenses_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"send_incenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_payouts/:id/paid(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paid_operators_customer_customer_payout_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_payouts/:id/paid(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paid_users_customer_managements_customer_customer_payout_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:id/paper(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paper_users_customer_managements_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"paper"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customers/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_users_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/picking(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const picking_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"picking"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /plannings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planning_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plannings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /plannings/:planning_id/products/:id(.:format)
 * @param {any} planning_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planning_product_path = __jsr.r({"planning_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plannings"],[2,[7,"/"],[2,[3,"planning_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /plannings/:planning_id/product_sets/:id(.:format)
 * @param {any} planning_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planning_product_set_path = __jsr.r({"planning_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plannings"],[2,[7,"/"],[2,[3,"planning_id"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /plannings/:planning_id/registers(.:format)
 * @param {any} planning_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planning_registers_path = __jsr.r({"planning_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plannings"],[2,[7,"/"],[2,[3,"planning_id"],[2,[7,"/"],[2,[6,"registers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/:id/print_address(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_address_operators_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:id/print_address(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_address_users_customer_managements_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bellmony/membership_card_summary_details/print(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_users_customer_managements_bellmony_membership_card_summary_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"membership_card_summary_details"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/memorial_services/:id/print(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_users_customer_managements_customer_customer_funeral_target_memorial_service_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/time_schedule/print(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_users_customer_managements_customer_customer_funeral_target_time_schedule_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"time_schedule"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id/print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receivable_account_bellmonies/print(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_users_customer_managements_receivable_account_bellmonies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receivable_account_bellmonies"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/printed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const printed_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"printed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id/printed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const printed_users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"printed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/remains_photos/processed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const processed_remains_photo_staffs_remains_photos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[6,"processed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/product_not_assigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_not_assigns_users_customer_managements_estimates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[6,"product_not_assigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_admins_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/operators/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_admins_operator_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_admins_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_staffs/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_admins_remains_photo_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_users_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organization_users/:id/proxy_sign_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proxy_sign_in_users_organization_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organization_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"proxy_sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/:id/ready(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_operators_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:id/ready(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_users_customer_managements_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /receipts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:id/receipt(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipt_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deposits/receipts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receipts_users_customer_managements_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/refresh_kinichi(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_kinichi_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh_kinichi"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/refund_confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refund_confirm_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refund_confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refund_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/refundable_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refundable_detail_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refundable_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customers/:id/registered(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registered_users_customer_managements_simple_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"registered"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customer_funeral_targets/:customer_funeral_target_id/estimates/:id/registered(.:format)
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registered_users_customer_managements_simple_customer_funeral_target_estimate_path = __jsr.r({"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"registered"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staff(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staff"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staff_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/remains_photos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staffs_remains_photo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/remains_photos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staffs_remains_photos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"remains_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remains_photo_staffs_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_funerals_funeral_item_payouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_pages/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_my_page_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_pages"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_operator_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/remove(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_p_funeral_attendant_attendant_item_payouts_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_remains_photo_staff_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id/reprint(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reprint_users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reprint"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/otp/resend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_users_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"otp"],[2,[7,"/"],[2,[6,"resend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/:id/returned_products(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const returned_products_users_customer_managements_repayment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"returned_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/cm_sign_dossier/save_signed_file(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_signed_file_users_customer_managements_estimate_cm_sign_dossier_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"cm_sign_dossier"],[2,[7,"/"],[2,[6,"save_signed_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/orders/:id/select_funeral(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_funeral_o_order_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"select_funeral"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/inquiries/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_inquiries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/meetings/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_meetings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/organization_files/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_organization_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/partners/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_partners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/rota/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_rota_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/sms_logs/select_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_organization_operators_sms_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[6,"select_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/invoice_e_scott_mails/:id/send_email(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_email_users_customer_managements_invoice_invoice_e_scott_invoice_e_scott_mail_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"invoice_e_scott_mails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /order_to_suppliers/:id/send_fax(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_fax_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_fax"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/cm_sign_dossier/send_invites(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_invites_users_customer_managements_estimate_cm_sign_dossier_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"cm_sign_dossier"],[2,[7,"/"],[2,[6,"send_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_base(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_base_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_base"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_base(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_base_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_base"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_finished(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_finished_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_finished"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_finished(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_finished_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_finished"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_started(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_started_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_started"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/send_mail_order_started(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_order_started_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail_order_started"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id/send_mail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/send_mail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/send_mail(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_users_customer_managements_invoice_invoice_e_scott_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"send_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id/send_mail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_mail_users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_mail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:id/send_sms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_sms_users_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/product_purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_admins_organization_product_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_admins_organization_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/returned_item_purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_admins_organization_returned_item_purchase_order_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:id/send_to_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_users_customer_managements_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_users_product_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_users_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/returned_item_purchase_orders/:id/send_to_supplier(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_supplier_users_returned_item_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_supplier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_funeral_targets/:id/sending_paper(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sending_paper_operators_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sending_paper"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id/sending_paper(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sending_paper_users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sending_paper"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sht/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortened_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sht"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/show_coop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_coop_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_coop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/show_customer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_customer_users_customer_managements_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"show_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/show_holding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_holding_users_customer_managements_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"show_holding"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/show_tokaitenrei(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_tokaitenrei_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_tokaitenrei"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sms/status_callback/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_status_callback_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"status_callback"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_list_settings/sort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_customer_list_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_list_settings"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/estimate_details/sort(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_estimate_estimate_details_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"estimate_details"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_details/sort(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_invoice_invoice_details_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_details"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_tablet_product_menus/sort(.:format)
 * @param {any} bland_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_manages_bland_bland_tablet_product_menus_path = __jsr.r({"bland_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_tablet_product_menus"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:catalog_id/catalog_images/sort(.:format)
 * @param {any} catalog_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_manages_catalog_catalog_images_path = __jsr.r({"catalog_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"catalog_id"],[2,[7,"/"],[2,[6,"catalog_images"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:tablet_product_menu_id/tablet_product_menu_products/sort(.:format)
 * @param {any} tablet_product_menu_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_manages_tablet_product_menu_tablet_product_menu_products_path = __jsr.r({"tablet_product_menu_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"tablet_product_menu_id"],[2,[7,"/"],[2,[6,"tablet_product_menu_products"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/sort(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sort_users_customer_managements_manages_tablet_product_menus_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[6,"sort"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_list_settings/sorts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sorts_users_customer_managements_customer_list_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_list_settings"],[2,[7,"/"],[2,[6,"sorts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_tablet_product_menus/sorts(.:format)
 * @param {any} bland_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sorts_users_customer_managements_manages_bland_bland_tablet_product_menus_path = __jsr.r({"bland_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_tablet_product_menus"],[2,[7,"/"],[2,[6,"sorts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /call/status_callback/:client_id(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_callback_call_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"call"],[2,[7,"/"],[2,[6,"status_callback"],[2,[7,"/"],[2,[3,"client_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/:id/stripe_cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_funerals_funeral_item_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id/stripe_cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts/:id/stripe_cancel(.:format)
 * @param {any} membership_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_membership_customer_customer_payout_path = __jsr.r({"membership_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:id/stripe_cancel(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/:id/stripe_cancel(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_p_funeral_attendant_attendant_item_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:id/stripe_cancel(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_cancel_p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_item_payouts/:id/stripe_success(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_funerals_funeral_item_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/funeral_payouts/:id/stripe_success(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_funerals_funeral_payout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"funeral_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:membership_id/customer/customer_payouts/:id/stripe_success(.:format)
 * @param {any} membership_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_membership_customer_customer_payout_path = __jsr.r({"membership_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"membership_id"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"customer_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:id/stripe_success(.:format)
 * @param {any} o_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_o_attendant_attendant_payout_path = __jsr.r({"o_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_item_payouts/:id/stripe_success(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_p_funeral_attendant_attendant_item_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_item_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:id/stripe_success(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_success_p_funeral_attendant_attendant_payout_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stripe_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /suppliers/:supplier_id/order_to_suppliers(.:format)
 * @param {any} supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_order_to_suppliers_path = __jsr.r({"supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"supplier_id"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operators/customers/:customer_id/customer_comments/:id/support(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_operators_customer_customer_comment_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operators"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_comments/:id/support(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_users_customer_managements_customer_customer_comment_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /os/:o_id/attendant/attendant_payouts/:attendant_payout_id/incense/three_d_secure_return(.:format)
 * @param {any} o_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const three_d_secure_return_o_attendant_attendant_payout_incense_path = __jsr.r({"o_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"os"],[2,[7,"/"],[2,[3,"o_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[2,[7,"/"],[2,[6,"three_d_secure_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/f/:funeral_id/attendant/attendant_payouts/:attendant_payout_id/incense/three_d_secure_return(.:format)
 * @param {any} funeral_id
 * @param {any} attendant_payout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const three_d_secure_return_p_funeral_attendant_attendant_payout_incense_path = __jsr.r({"funeral_id":{"r":true},"attendant_payout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[6,"f"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant"],[2,[7,"/"],[2,[6,"attendant_payouts"],[2,[7,"/"],[2,[3,"attendant_payout_id"],[2,[7,"/"],[2,[6,"incense"],[2,[7,"/"],[2,[6,"three_d_secure_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/toggle_check_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_check_all_users_customer_managements_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"toggle_check_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id/toggle_check_invoice(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_check_invoice_users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_check_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /remains_photo_staffs/remains_photos/unassigned(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unassigned_remains_photo_staffs_remains_photos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"remains_photo_staffs"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[6,"unassigned"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:id/unlock(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts/:id/unlock(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_users_customer_managements_invoice_invoice_receipt_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users/:id/unlock(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_users_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/after_sale_items/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"after_sale_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolences/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_condolence_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolences"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/condolence_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_condolence_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"condolence_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/crematoria/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_crematorium_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"crematoria"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/documents/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_memorials/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/funeral_movies/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_guide_letter_templates/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_funeral_guide_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_guide_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funeral_letter_templates/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_funeral_letter_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funeral_letter_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/funerals/:funeral_id/services/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greetings/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_greeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greetings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/greeting_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_greeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"greeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/help_videos/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/memorial_services/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_memorial_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/news_items/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_news_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"news_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:id/up(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_organization_product_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/organizations/:organization_id/products/:product_id/product_images/:id/up(.:format)
 * @param {any} organization_id
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_organization_product_product_image_path = __jsr.r({"organization_id":{"r":true},"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/plans/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/product_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/remains_photo_costumes/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_admins_remains_photo_costume_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"remains_photo_costumes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/funeral_memorials/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_funerals_dashboard_funeral_memorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /funerals/dashboard/services/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_funerals_dashboard_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:customer_funeral_target_invoice_id/customer_funeral_target_invoice_invoices/:id/up(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} customer_funeral_target_invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_customer_funeral_target_invoice_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"customer_funeral_target_invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"customer_funeral_target_invoice_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoice_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_hibiya_hibiya_enforcer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_hibiya_hibiya_mail_recipient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_inquiry_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_after_sale_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_area_for_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_billing_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_billing_transfer_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_billing_update_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_bland_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_boarding_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_car_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_catalog_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_chartered_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_customer_comment_tag_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_customer_comment_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_customer_funeral_target_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_customer_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_deposit_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_deposit_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_escalation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_estimate_confirmation_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_estimate_title_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_floral_tribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_floral_tribute_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_florist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_hope_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_hope_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_scale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_funeral_target_manager_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_inquiry_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_inquiry_order_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_invoice_additional_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_lounge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_mail_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_media_trigger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_meeting_behavior_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_meeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_member_benefit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_member_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_member_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_member_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_order_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/up(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_payment_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_person_in_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_phase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_pick_up_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_images/:id/up(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_product_set_product_set_image_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_products/:id/up(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_product_set_product_set_product_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_selects/:id/up(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_product_set_product_set_select_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_selects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_select_products/:id/up(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_product_set_product_set_select_product_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_select_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_proviso_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_purchase_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_purify_manner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_purify_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_purpose_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_rough_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_rough_product_osonae_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_sale_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_sending_funeral_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_sms_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_tablet_product_menu_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:tablet_product_menu_id/tablet_product_menu_products/:id/up(.:format)
 * @param {any} tablet_product_menu_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_tablet_product_menu_tablet_product_menu_product_path = __jsr.r({"tablet_product_menu_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"tablet_product_menu_id"],[2,[7,"/"],[2,[6,"tablet_product_menu_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_transport_destination_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_manages_transport_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id/up(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_customer_managements_self_planning_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_discounted_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_memorials/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_movies/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services/:id/up(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_bundle_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children/:id/up(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_kind_child_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/products/:id/up(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_kind_product_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_pattern_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_images/:id/up(.:format)
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_product_product_image_path = __jsr.r({"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_remark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venues/:id/up(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const up_users_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_duplications/update_duplications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_duplications_users_customer_managements_customer_duplications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_duplications"],[2,[7,"/"],[2,[6,"update_duplications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:id/update_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_invites_users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:id/update_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_invites_users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:id/update_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_invites_users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:id/update_invites(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_invites_users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/invoice_details/:id/update_pattern(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_pattern_users_customer_managements_customer_customer_funeral_target_invoice_detail_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"invoice_details"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_pattern"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /order_to_suppliers/:id/update_print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_print_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /purchase_orders/:id/update_print(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_print_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_users_customer_managements_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/allowed_ips/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_allowed_ip_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"allowed_ips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/allowed_ips(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_allowed_ips_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"allowed_ips"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/attendants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_attendants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"attendants"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/autocomplete_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_autocomplete_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"autocomplete_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/bank_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/bank_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/ctis/dashboards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_ctis_dashboards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"ctis"],[2,[7,"/"],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/ctis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_ctis_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"ctis"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledgers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_general_ledger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledgers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledger_relationships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_general_ledger_relationship_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledger_relationships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledger_relationships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_general_ledger_relationships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledger_relationships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/general_ledgers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_general_ledgers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"general_ledgers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/journal_data_outputs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_journal_data_outputs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"journal_data_outputs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/subsidiary_ledgers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_subsidiary_ledger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"subsidiary_ledgers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/accounts/subsidiary_ledgers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_accounts_subsidiary_ledgers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"subsidiary_ledgers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/applicant_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_applicant_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"applicant_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/applicant_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_applicant_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"applicant_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/approve_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_approve_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"approve_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/approve_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_approve_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"approve_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/background_jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_background_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"background_jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/background_jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_background_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"background_jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/batch_deposit_locks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_batch_deposit_lock_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"batch_deposit_locks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/batch_deposit_locks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_batch_deposit_locks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"batch_deposit_locks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bellmony/membership_card_summary_details/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_bellmony_membership_card_summary_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"membership_card_summary_details"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bellmony/membership_card_summary_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_bellmony_membership_card_summary_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"membership_card_summary_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/boards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_board_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"boards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/boards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_boards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"boards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/bulk_register_invoice(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_bulk_register_invoice_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"bulk_register_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/call_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_call_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"call_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/coop/memoru_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_coop_memoru_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"coop"],[2,[7,"/"],[2,[6,"memoru_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sale_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sale_customer_after_sale_action_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_actions(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sale_customer_after_sale_actions_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_actions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_partners/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sale_customer_after_sale_partner_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales/:customer_after_sale_id/customer_after_sale_partners(.:format)
 * @param {any} customer_id
 * @param {any} customer_after_sale_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sale_customer_after_sale_partners_path = __jsr.r({"customer_id":{"r":true},"customer_after_sale_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[2,[7,"/"],[2,[3,"customer_after_sale_id"],[2,[7,"/"],[2,[6,"customer_after_sale_partners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_after_sales(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_after_sales_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_after_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_comments/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_comment_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_comments(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_comments_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_files/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_file_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_files(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_files_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/aguri/condolence_article(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_aguri_condolence_article_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"aguri"],[2,[7,"/"],[2,[6,"condolence_article"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/aguri/hall_confirmation(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_aguri_hall_confirmation_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"aguri"],[2,[7,"/"],[2,[6,"hall_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/bellmony/purchase_orders(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_bellmony_purchase_orders_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/coop/uketamawari(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_coop_uketamawari_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"coop"],[2,[7,"/"],[2,[6,"uketamawari"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_files/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_file_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_files(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_files_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices/:customer_funeral_target_invoice_id/customer_funeral_target_invoice_invoices(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} customer_funeral_target_invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoice_customer_funeral_target_invoice_invoices_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"customer_funeral_target_invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[2,[7,"/"],[2,[3,"customer_funeral_target_invoice_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoice_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_invoices(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_invoices_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_manager_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_managers(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_managers_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_managers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_receipt_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/customer_funeral_target_receipts(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_customer_funeral_target_receipts_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"customer_funeral_target_receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/invoice_details/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_invoice_detail_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"invoice_details"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/invoices(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_invoices_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/keiou/genkahyou(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_keiou_genkahyou_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"keiou"],[2,[7,"/"],[2,[6,"genkahyou"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/memorial_services/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_memorial_service_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"memorial_services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/memorial_services(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_memorial_services_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"memorial_services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/schedule_excel(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_schedule_excel_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"schedule_excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/shintosya/format_downloads(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_shintosya_format_downloads_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"shintosya"],[2,[7,"/"],[2,[6,"format_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets/:customer_funeral_target_id/time_schedule(.:format)
 * @param {any} customer_id
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_target_time_schedule_path = __jsr.r({"customer_id":{"r":true},"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"time_schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_funeral_targets(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_funeral_targets_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_linkages/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_linkage_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_linkages(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_linkages_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_linkages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_meetings/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_meeting_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_meetings/:customer_meeting_id/customer_meeting_behaviors/:id(.:format)
 * @param {any} customer_id
 * @param {any} customer_meeting_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_meeting_customer_meeting_behavior_path = __jsr.r({"customer_id":{"r":true},"customer_meeting_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"customer_meeting_id"],[2,[7,"/"],[2,[6,"customer_meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_meetings/:customer_meeting_id/customer_meeting_behaviors(.:format)
 * @param {any} customer_id
 * @param {any} customer_meeting_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_meeting_customer_meeting_behaviors_path = __jsr.r({"customer_id":{"r":true},"customer_meeting_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[2,[7,"/"],[2,[3,"customer_meeting_id"],[2,[7,"/"],[2,[6,"customer_meeting_behaviors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_meetings(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_meetings_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_meetings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_ossuaries_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/cremated_remains/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_ossuaries_cremated_remain_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"cremated_remains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_ossuaries/cremated_remains(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_ossuaries_cremated_remains_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_ossuaries"],[2,[7,"/"],[2,[6,"cremated_remains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_states/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_state_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_states"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/customer_users(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_customer_users_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"customer_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_duplications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_duplication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_duplications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_duplications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_duplications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_duplications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_funeral_targets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_funeral_target_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_funeral_targets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_funeral_targets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/customer_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/invite(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_invite_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/invoice_target_invoices(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_invoice_target_invoices_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"invoice_target_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms/:iwasaki_cancellation_form_id/lock(.:format)
 * @param {any} customer_id
 * @param {any} iwasaki_cancellation_form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_cancellation_form_lock_path = __jsr.r({"customer_id":{"r":true},"iwasaki_cancellation_form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[2,[7,"/"],[2,[3,"iwasaki_cancellation_form_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_cancellation_forms(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_cancellation_forms_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_cancellation_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms/:iwasaki_enrollment_form_id/lock(.:format)
 * @param {any} customer_id
 * @param {any} iwasaki_enrollment_form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_enrollment_form_lock_path = __jsr.r({"customer_id":{"r":true},"iwasaki_enrollment_form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[2,[7,"/"],[2,[3,"iwasaki_enrollment_form_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_enrollment_forms(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_enrollment_forms_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_enrollment_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms/:iwasaki_kokoro_consent_form_id/lock(.:format)
 * @param {any} customer_id
 * @param {any} iwasaki_kokoro_consent_form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_form_lock_path = __jsr.r({"customer_id":{"r":true},"iwasaki_kokoro_consent_form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[2,[7,"/"],[2,[3,"iwasaki_kokoro_consent_form_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_kokoro_consent_forms(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_kokoro_consent_forms_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_kokoro_consent_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms/:iwasaki_usage_consent_form_id/lock(.:format)
 * @param {any} customer_id
 * @param {any} iwasaki_usage_consent_form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_usage_consent_form_lock_path = __jsr.r({"customer_id":{"r":true},"iwasaki_usage_consent_form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[2,[7,"/"],[2,[3,"iwasaki_usage_consent_form_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/iwasaki/iwasaki_usage_consent_forms(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_iwasaki_iwasaki_usage_consent_forms_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"iwasaki_usage_consent_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_list_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_list_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_list_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customer_list_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_list_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customer_list_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_member_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:member_id/member_payments/:id(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_member_member_payment_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members/:member_id/member_payments(.:format)
 * @param {any} customer_id
 * @param {any} member_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_member_member_payments_path = __jsr.r({"customer_id":{"r":true},"member_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[2,[7,"/"],[2,[3,"member_id"],[2,[7,"/"],[2,[6,"member_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/members(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_members_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/my_page(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_my_page_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"my_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/send_smss(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_send_smss_index_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_smss"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/sms_log_customers(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_sms_log_customers_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/tablets/products/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_tablets_product_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers/:customer_id/tablets/product_sets/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customer_tablets_product_set_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"tablets"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_deliveries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_delivery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries/direct_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_direct_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[6,"direct_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/deliveries/direct_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_direct_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"deliveries"],[2,[7,"/"],[2,[6,"direct_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/cm_sign_dossier(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_cm_sign_dossier_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"cm_sign_dossier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/estimate_details(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_estimate_details_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"estimate_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/invoices(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_invoices_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/kochi/excel(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_kochi_excel_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"kochi"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/lock(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_lock_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates/:estimate_id/sign(.:format)
 * @param {any} estimate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimate_sign_path = __jsr.r({"estimate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[2,[7,"/"],[2,[3,"estimate_id"],[2,[7,"/"],[2,[6,"sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/estimates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_estimates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"estimates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/hakkoden_actual_stocks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_hakkoden_actual_stock_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"hakkoden_actual_stocks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/hakkoden_actual_stocks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_hakkoden_actual_stocks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"hakkoden_actual_stocks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/kanjou_bugyous(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_kanjou_bugyous_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"kanjou_bugyous"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_adjustments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_adjustments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_adjustments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_adjustments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_adjustments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_to_supplier_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_to_supplier_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_to_supplier_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_to_supplier_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_to_supplier_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/order_to_suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_order_to_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/sale_charts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_sale_charts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"sale_charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hakkoden/sale_summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hakkoden_sale_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"sale_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/help_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_help_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"help_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/help_videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_help_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"help_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_enforcer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_enforcers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_enforcers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_enforcers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_expense_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_expenses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_mail_recipient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_mail_recipients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_hibiya_mail_recipients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_mail_recipients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/sales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_hibiya_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:inquiry_id/customers/:id(.:format)
 * @param {any} inquiry_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_customer_path = __jsr.r({"inquiry_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:inquiry_id/customers/customer_comments(.:format)
 * @param {any} inquiry_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_customer_comments_path = __jsr.r({"inquiry_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"customer_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiries/:inquiry_id/customers(.:format)
 * @param {any} inquiry_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_customers_path = __jsr.r({"inquiry_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiries"],[2,[7,"/"],[2,[3,"inquiry_id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/inquiry_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_inquiry_statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"inquiry_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/estimates(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_estimates_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"estimates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_details(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_details_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_e_scott_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/invoice_e_scott_mails/:id(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_e_scott_invoice_e_scott_mail_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"invoice_e_scott_mails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_e_scott/invoice_e_scott_mails(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_e_scott_invoice_e_scott_mails_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_e_scott"],[2,[7,"/"],[2,[6,"invoice_e_scott_mails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_payments/:id(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_payment_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_payments(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_payments_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts/:id(.:format)
 * @param {any} invoice_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_receipt_path = __jsr.r({"invoice_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/invoice_receipts(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_invoice_receipts_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices/:invoice_id/lock(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_lock_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoice_summaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoice_summary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoice_summaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/iwasaki/member_form_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_iwasaki_member_form_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"member_form_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/iwasaki/tkc_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_iwasaki_tkc_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"iwasaki"],[2,[7,"/"],[2,[6,"tkc_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/kaiwa_cloud_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_kaiwa_cloud_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"kaiwa_cloud_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/kaiwa_cloud_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_kaiwa_cloud_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"kaiwa_cloud_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/kanjou_bugyous(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_kanjou_bugyous_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"kanjou_bugyous"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/kochi(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_kochi_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"kochi"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/kochi/treasurers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_kochi_treasurers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"kochi"],[2,[7,"/"],[2,[6,"treasurers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/latest_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_latest_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"latest_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/lec/kanjo_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_lec_kanjo_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"lec"],[2,[7,"/"],[2,[6,"kanjo_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/locks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_locks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"locks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_after_sale_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/after_sale_actions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_after_sale_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"after_sale_actions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_area_for_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_for_my_temples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_area_for_my_temples_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_for_my_temples"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_area_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/area_goals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_area_goals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"area_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/areas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_areas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/at_temples/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_at_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"at_temples"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/at_temples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_at_temples_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"at_temples"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/bellmony/excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bellmony_excel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_transfer_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_transfer_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_transfer_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_transfer_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_update_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/billing_update_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_billing_update_statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"billing_update_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_product_sets/:id(.:format)
 * @param {any} bland_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_product_set_path = __jsr.r({"bland_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_product_sets(.:format)
 * @param {any} bland_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_product_sets_path = __jsr.r({"bland_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_product_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_tablet_product_menus/:id(.:format)
 * @param {any} bland_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_tablet_product_menu_path = __jsr.r({"bland_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_tablet_product_menus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_tablet_product_menus(.:format)
 * @param {any} bland_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_tablet_product_menus_path = __jsr.r({"bland_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_tablet_product_menus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_venues/:id(.:format)
 * @param {any} bland_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_venue_path = __jsr.r({"bland_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_venues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands/:bland_id/bland_venues(.:format)
 * @param {any} bland_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_bland_venues_path = __jsr.r({"bland_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[2,[7,"/"],[2,[3,"bland_id"],[2,[7,"/"],[2,[6,"bland_venues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/bland_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_bland_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"bland_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/blands(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_blands_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"blands"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_boarding_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/boarding_places(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_boarding_places_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"boarding_places"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_car_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/car_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_car_models_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"car_models"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_catalog_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:catalog_id/catalog_images/:id(.:format)
 * @param {any} catalog_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_catalog_catalog_image_path = __jsr.r({"catalog_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"catalog_id"],[2,[7,"/"],[2,[6,"catalog_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs/:catalog_id/catalog_images(.:format)
 * @param {any} catalog_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_catalog_catalog_images_path = __jsr.r({"catalog_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[2,[7,"/"],[2,[3,"catalog_id"],[2,[7,"/"],[2,[6,"catalog_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/catalogs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_catalogs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"catalogs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_chartered_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/chartered_organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_chartered_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"chartered_organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/coffins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_coffin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"coffins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/coffins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_coffins_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"coffins"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/crematorium_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_crematorium_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"crematorium_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/crematorium_goals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_crematorium_goals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"crematorium_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_comment_tag_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_tag_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_comment_tag_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_tag_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_comment_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_comment_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_comment_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_comment_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_funeral_target_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_funeral_target_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_funeral_target_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_funeral_target_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/customer_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_customer_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"customer_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_deposit_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_deposit_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_deposit_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/deposit_places(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_deposit_places_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"deposit_places"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/e_scott_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_e_scott_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"e_scott_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/e_scott_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_e_scott_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"e_scott_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_emergency_contact_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/emergency_contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_emergency_contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"emergency_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_escalation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/escalations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_escalations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"escalations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_estimate_confirmation_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_confirmation_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_estimate_confirmation_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_confirmation_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_estimate_title_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/estimate_title_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_estimate_title_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"estimate_title_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_floral_tribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_floral_tribute_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_floral_tribute_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/floral_tribute_styles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_floral_tribute_styles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"floral_tribute_styles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_florist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/florists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_florists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"florists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_hope_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_areas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_hope_areas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_hope_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_hope_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_hope_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_hope_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_scale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_scales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_scales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_scales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_style_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_styles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_styles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_styles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_target_manager_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/funeral_target_manager_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_funeral_target_manager_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"funeral_target_manager_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflow_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inflow_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflow_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflow_goals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inflow_goals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflow_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inquiry_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inquiry_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inquiry_order_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/inquiry_order_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_inquiry_order_statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"inquiry_order_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_invoice_additional_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/invoice_additional_contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_invoice_additional_contents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"invoice_additional_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/keiou/excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_keiou_excel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"keiou"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/kochi/excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_kochi_excel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"kochi"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/life_plan_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_life_plan_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"life_plan_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/life_plan_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_life_plan_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"life_plan_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_lounge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/lounges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_lounges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"lounges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_mail_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/mail_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_mail_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"mail_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/manuals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_manuals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"manuals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_media_trigger_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/media_triggers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_media_triggers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"media_triggers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_meeting_behavior_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_behaviors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_meeting_behaviors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_behaviors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_meeting_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/meeting_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_meeting_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"meeting_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_benefit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_benefits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_benefits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_benefits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_inflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_inflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_inflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/member_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_member_statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"member_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_my_temple_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples/my_temple_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_my_temple_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[2,[7,"/"],[2,[6,"my_temple_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/my_temples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_my_temples_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"my_temples"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/notice_setting_funerals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_notice_setting_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"notice_setting_funerals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/notice_setting_funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_notice_setting_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"notice_setting_funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_order_inflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/order_inflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_order_inflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"order_inflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_after_sale_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_item_actions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_after_sale_item_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_item_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_item_actions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_after_sale_item_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_item_actions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_after_sale_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_after_sale_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_after_sale_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_attribute_kind_organization_attribute_masters_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_attribute_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_attribute_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_crematoria(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_crematoria_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_crematoria"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_crematoria/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_crematorium_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_crematoria"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_crematorium_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_crematorium_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_crematorium_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_memorial_services/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_memorial_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_memorial_services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_memorial_services/:organization_memorial_service_id/dates/:id(.:format)
 * @param {any} organization_memorial_service_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_memorial_service_date_path = __jsr.r({"organization_memorial_service_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_memorial_services"],[2,[7,"/"],[2,[3,"organization_memorial_service_id"],[2,[7,"/"],[2,[6,"dates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_memorial_services/:organization_memorial_service_id/dates(.:format)
 * @param {any} organization_memorial_service_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_memorial_service_dates_path = __jsr.r({"organization_memorial_service_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_memorial_services"],[2,[7,"/"],[2,[3,"organization_memorial_service_id"],[2,[7,"/"],[2,[6,"dates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/organization_memorial_services(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_organization_memorial_services_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"organization_memorial_services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_payment_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_payment_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/payment_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_payment_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_person_in_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/person_in_charges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_person_in_charges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"person_in_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_phase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/phases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_phases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"phases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_pick_up_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_location_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_pick_up_location_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_location_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/pick_up_locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_pick_up_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"pick_up_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_goals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_goals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_set_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_set_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_set_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_images/:id(.:format)
 * @param {any} product_set_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_set_product_set_image_path = __jsr.r({"product_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets/:product_set_id/product_set_images(.:format)
 * @param {any} product_set_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_set_product_set_images_path = __jsr.r({"product_set_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[2,[7,"/"],[2,[3,"product_set_id"],[2,[7,"/"],[2,[6,"product_set_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_sets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stock_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_stock_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stock_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stocks/venues/:venue_id/products/:product_id/product_stocks/:id(.:format)
 * @param {any} venue_id
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_stocks_venue_product_product_stock_path = __jsr.r({"venue_id":{"r":true},"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stocks"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"venue_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_stocks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stocks/venues/:venue_id/products/:product_id/product_stocks(.:format)
 * @param {any} venue_id
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_stocks_venue_product_product_stocks_path = __jsr.r({"venue_id":{"r":true},"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stocks"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"venue_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_stocks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stocks/venues/:venue_id/products(.:format)
 * @param {any} venue_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_stocks_venue_products_path = __jsr.r({"venue_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stocks"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"venue_id"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/product_stocks/venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_product_stocks_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"product_stocks"],[2,[7,"/"],[2,[6,"venues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_proviso_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/provisos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_provisos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"provisos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purchase_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purchase_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purchase_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purchase_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purify_manner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_manners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purify_manners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_manners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purify_place_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purify_places(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purify_places_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purify_places"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purpose_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/purpose_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_purpose_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"purpose_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_rough_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_rough_product_osonae_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_product_osonaes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_rough_product_osonaes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_product_osonaes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/rough_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_rough_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"rough_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sale_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sale_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_goals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sale_goal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_goals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sale_goals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sale_goals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sale_goals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/schedules/excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_schedules_excel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sending_funeral_home_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sending_funeral_homes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sending_funeral_homes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sending_funeral_homes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/shintosya/excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_shintosya_excel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"shintosya"],[2,[7,"/"],[2,[6,"excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/shonanokas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_shonanoka_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"shonanokas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/shonanokas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_shonanokas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"shonanokas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_auto_sents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sms_auto_sent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_auto_sents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sms_auto_sents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sms_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/sms_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_sms_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"sms_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_tablet_product_menu_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menu_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_tablet_product_menu_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menu_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:tablet_product_menu_id/tablet_product_menu_products/:id(.:format)
 * @param {any} tablet_product_menu_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_tablet_product_menu_tablet_product_menu_product_path = __jsr.r({"tablet_product_menu_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"tablet_product_menu_id"],[2,[7,"/"],[2,[6,"tablet_product_menu_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus/:tablet_product_menu_id/tablet_product_menu_products(.:format)
 * @param {any} tablet_product_menu_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_tablet_product_menu_tablet_product_menu_products_path = __jsr.r({"tablet_product_menu_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[2,[7,"/"],[2,[3,"tablet_product_menu_id"],[2,[7,"/"],[2,[6,"tablet_product_menu_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/tablet_product_menus(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_tablet_product_menus_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"tablet_product_menus"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/talk_manuals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_talk_manual_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"talk_manuals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/talk_manuals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_talk_manuals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"talk_manuals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/time_schedule_masters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_time_schedule_master_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"time_schedule_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/time_schedule_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_time_schedule_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"time_schedule_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_transport_destination_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_destinations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_transport_destinations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_destinations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_transport_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/transport_infos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_transport_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"transport_infos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/waiting_rooms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_waiting_room_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"waiting_rooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/waiting_rooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_waiting_rooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"waiting_rooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/yukans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_yukan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"yukans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/manages/yukans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_manages_yukans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"manages"],[2,[7,"/"],[2,[6,"yukans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/meetings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_meeting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"meetings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/meetings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_meetings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"meetings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/member_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_member_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"member_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/notices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"notices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_supplier_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_supplier_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/lock(.:format)
 * @param {any} order_to_supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_lock_path = __jsr.r({"order_to_supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_comments/:id(.:format)
 * @param {any} order_to_supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_order_to_supplier_comment_path = __jsr.r({"order_to_supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_comments(.:format)
 * @param {any} order_to_supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_order_to_supplier_comments_path = __jsr.r({"order_to_supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_payments/:id(.:format)
 * @param {any} order_to_supplier_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_order_to_supplier_payment_path = __jsr.r({"order_to_supplier_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers/:order_to_supplier_id/order_to_supplier_payments(.:format)
 * @param {any} order_to_supplier_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_supplier_order_to_supplier_payments_path = __jsr.r({"order_to_supplier_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[2,[7,"/"],[2,[3,"order_to_supplier_id"],[2,[7,"/"],[2,[6,"order_to_supplier_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/order_to_suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_order_to_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"order_to_suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_after_sale_notices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_after_sale_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_after_sale_notices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_attribute_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters/:id(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_attribute_kind_organization_attribute_master_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds/:organization_attribute_kind_id/organization_attribute_masters(.:format)
 * @param {any} organization_attribute_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_attribute_kind_organization_attribute_masters_path = __jsr.r({"organization_attribute_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[2,[7,"/"],[2,[3,"organization_attribute_kind_id"],[2,[7,"/"],[2,[6,"organization_attribute_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_attribute_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_attribute_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_attribute_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/organization_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_organization_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"organization_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_partner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_after_sale_item(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_partner_partner_after_sale_item_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_after_sale_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_staffs/:id(.:format)
 * @param {any} partner_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_partner_partner_staff_path = __jsr.r({"partner_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners/:partner_id/partner_staffs(.:format)
 * @param {any} partner_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_partner_partner_staffs_path = __jsr.r({"partner_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[2,[7,"/"],[2,[3,"partner_id"],[2,[7,"/"],[2,[6,"partner_staffs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/partners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_partners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"partners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/payable_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_payable_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"payable_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_phone_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/phone/users/connect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_phone_users_connect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"phone"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"connect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receipt_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:receipt_id/invoice_receipts/:id(.:format)
 * @param {any} receipt_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receipt_invoice_receipt_path = __jsr.r({"receipt_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"receipt_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts/:receipt_id/invoice_receipts(.:format)
 * @param {any} receipt_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receipt_invoice_receipts_path = __jsr.r({"receipt_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[2,[7,"/"],[2,[3,"receipt_id"],[2,[7,"/"],[2,[6,"invoice_receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receipts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receipts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receipts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receivable_account_bellmonies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receivable_account_bellmonies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receivable_account_bellmonies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/receivable_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_receivable_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"receivable_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_repayment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayment_returned_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_repayment_returned_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayment_returned_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/repayments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_repayments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"repayments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/request_infos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_request_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"request_infos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rota(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_rota_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rota"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rota/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_rotum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rota"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/rotum_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_rotum_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"rotum_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sakura/mjs_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sakura_mjs_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sakura"],[2,[7,"/"],[2,[6,"mjs_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sale_downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sale_downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sale_downloads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/bellmony/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_bellmony_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/bellmony/totaling_sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_bellmony_totaling_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"bellmony"],[2,[7,"/"],[2,[6,"totaling_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/crematoria(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_crematoria_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"crematoria"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/customer_after_sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_customer_after_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"customer_after_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/customer_funeral_targets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_customer_funeral_targets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/funeral_counts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_funeral_counts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"funeral_counts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/funeral_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_funeral_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"funeral_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/hakkoden/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_hakkoden_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"hakkoden"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/inflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_inflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"inflows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/keiou/cost_charts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_keiou_cost_charts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"keiou"],[2,[7,"/"],[2,[6,"cost_charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/keiou/sale_charts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_keiou_sale_charts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"keiou"],[2,[7,"/"],[2,[6,"sale_charts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/order_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_order_rates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"order_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/religiouses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_religiouses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"religiouses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sales/wholes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sales_wholes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"wholes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/searched_post_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_searched_post_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"searched_post_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_customer_comment_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_customer_comment_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_customer_comment_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_customer_funeral_targets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_customer_funeral_targets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_discounted_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_discounted_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_discounted_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_estimate_title_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_estimate_title_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_estimate_title_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_funerals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_funerals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_mail_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_mail_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_mail_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_mail_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_mail_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_mail_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_pick_up_locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_pick_up_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_pick_up_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_product_patterns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_product_patterns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_product_patterns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_products/:selected_product_id/product_pairs(.:format)
 * @param {any} selected_product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_product_product_pairs_path = __jsr.r({"selected_product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_products"],[2,[7,"/"],[2,[3,"selected_product_id"],[2,[7,"/"],[2,[6,"product_pairs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_product_sets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_product_set_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_product_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_product_sets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_product_sets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_product_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_provisos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_provisos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_provisos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/selected_purpose_masters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_selected_purpose_masters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"selected_purpose_masters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_self_planning_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/self_planning_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_self_planning_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"self_planning_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customer_funeral_targets/:customer_funeral_target_id/estimates(.:format)
 * @param {any} customer_funeral_target_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_simple_customer_funeral_target_estimates_path = __jsr.r({"customer_funeral_target_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[2,[7,"/"],[2,[3,"customer_funeral_target_id"],[2,[7,"/"],[2,[6,"estimates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customer_funeral_targets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_simple_customer_funeral_targets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customer_funeral_targets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_simple_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_simple_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/simple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_simple_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"simple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_auto_sents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_auto_sent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_auto_sents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_auto_sents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_auto_sents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:sms_log_id/sms_log_customers/:id(.:format)
 * @param {any} sms_log_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_log_sms_log_customer_path = __jsr.r({"sms_log_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs/:sms_log_id/sms_log_customers(.:format)
 * @param {any} sms_log_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_log_sms_log_customers_path = __jsr.r({"sms_log_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[2,[7,"/"],[2,[3,"sms_log_id"],[2,[7,"/"],[2,[6,"sms_log_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/sms_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_sms_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"sms_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/tokaitenrei/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_tokaitenrei_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"tokaitenrei"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/view_invoice_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_customer_managements_view_invoice_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"view_invoice_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_discounted_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_product_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_discounted_product_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_product_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/discounted_products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_discounted_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"discounted_products"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/doorkeepers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_doorkeepers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"doorkeepers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/attendant_returned_items(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_attendant_returned_items_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"attendant_returned_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_guide_letters(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_guide_letters_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_guide_letters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_letter_prints/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_letter_print_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_letter_prints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_letter_prints(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_letter_prints_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_letter_prints"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_letters(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_letters_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_letters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_memorials/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_memorial_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_memorials(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_memorials_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_memorials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_movies/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_movie_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/funeral_movies(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_funeral_movies_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"funeral_movies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/invite(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_invite_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/remains_photos/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_remains_photo_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"remains_photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/remains_photos(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_remains_photos_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"remains_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services/:id(.:format)
 * @param {any} funeral_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_service_path = __jsr.r({"funeral_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals/:funeral_id/services(.:format)
 * @param {any} funeral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funeral_services_path = __jsr.r({"funeral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[2,[7,"/"],[2,[3,"funeral_id"],[2,[7,"/"],[2,[6,"services"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/funerals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_funerals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/group_product_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_group_product_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"group_product_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/oarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_oarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"oarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/monthly_attendants(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_monthly_attendants_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_attendants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/monthly_funerals(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_monthly_funerals_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_funerals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/monthly_sales(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_monthly_sales_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"monthly_sales"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organizations/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_attributes(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_attributes_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_kinichis(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_kinichis_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_kinichis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_products(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_products_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_roles/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_role_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organization_roles(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organization_roles_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organization_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/organizations(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_organizations_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/required_fields(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_required_fields_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"required_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/service_usage_fees/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_service_usage_fee_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/service_usage_fees(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_service_usage_fees_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"service_usage_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/shipping_fee(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_shipping_fee_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"shipping_fee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users/:id(.:format)
 * @param {any} organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_user_path = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/users(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_users_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations/:organization_id/y_osohshiki_setting(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_y_osohshiki_setting_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"y_osohshiki_setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/otp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"otp"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/payments/:payment_id/condolence(.:format)
 * @param {any} payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_payment_condolence_path = __jsr.r({"payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"payment_id"],[2,[7,"/"],[2,[6,"condolence"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_bundle_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:product_bundle_id/product_bundle_products/:id(.:format)
 * @param {any} product_bundle_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_bundle_product_bundle_product_path = __jsr.r({"product_bundle_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"product_bundle_id"],[2,[7,"/"],[2,[6,"product_bundle_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles/:product_bundle_id/product_bundle_products(.:format)
 * @param {any} product_bundle_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_bundle_product_bundle_products_path = __jsr.r({"product_bundle_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[2,[7,"/"],[2,[3,"product_bundle_id"],[2,[7,"/"],[2,[6,"product_bundle_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_bundles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_bundles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_bundles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/group_products/:id(.:format)
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_group_product_path = __jsr.r({"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"group_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/group_products(.:format)
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_group_products_path = __jsr.r({"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"group_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_image_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_image_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_image_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/product_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children/:id(.:format)
 * @param {any} product_kind_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kind_child_path = __jsr.r({"product_kind_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/children(.:format)
 * @param {any} product_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kind_children_path = __jsr.r({"product_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"children"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/organization_product_kind(.:format)
 * @param {any} product_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kind_organization_product_kind_path = __jsr.r({"product_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"organization_product_kind"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds/:product_kind_id/products(.:format)
 * @param {any} product_kind_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kind_products_path = __jsr.r({"product_kind_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[2,[7,"/"],[2,[3,"product_kind_id"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_kinds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_kinds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_pattern_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_patterns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_patterns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_patterns"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_images/:id(.:format)
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_product_image_path = __jsr.r({"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_images(.:format)
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_product_images_path = __jsr.r({"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_pairs/:id(.:format)
 * @param {any} product_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_product_pair_path = __jsr.r({"product_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_pairs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/products/:product_id/product_pairs(.:format)
 * @param {any} product_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_product_pairs_path = __jsr.r({"product_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"product_id"],[2,[7,"/"],[2,[6,"product_pairs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/product_purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_product_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"product_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_remark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/remarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_remarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/returned_item_purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_returned_item_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/returned_item_purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_returned_item_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"returned_item_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_bank_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_crematoria(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_crematoria_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_crematoria"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_ec_remarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_ec_remarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_ec_remarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_funeral_guide_letter_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_funeral_guide_letter_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_funeral_guide_letter_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_funeral_letter_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_funeral_letter_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_funeral_letter_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_my_temples(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_my_temples_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_my_temples"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_remarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_remarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_remarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/select_venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_select_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"select_venues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/selected_product_bundles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_selected_product_bundle_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"selected_product_bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/selected_product_bundles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_selected_product_bundles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"selected_product_bundles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/selected_suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_selected_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"selected_suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/selected_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_selected_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"selected_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/send_purchase_notices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_send_purchase_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_purchase_notices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/send_purchase_notices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_send_purchase_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_purchase_notices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/stripe_tos_accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_stripe_tos_accept_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"stripe_tos_accept"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_supplier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/supplier_imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_supplier_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"supplier_imports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/supplier_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_supplier_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"supplier_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/suppliers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_suppliers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"suppliers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/tes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_te_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"tes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venues/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_venue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/venue_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_venue_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venue_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/venues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_venues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"venues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/veritrans_refund_confirm(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const veritrans_refund_confirm_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"veritrans_refund_confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sales/:id/veritrans_refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const veritrans_refund_users_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"veritrans_refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /e_scotts/:id/webhook_cnv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_cnv_e_scott_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"e_scotts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"webhook_cnv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /e_scotts/:id/webhook(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_e_scott_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"e_scotts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mjys0ueng1/kintones/:id/webhook(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_kintone_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"mjys0ueng1"],[2,[7,"/"],[2,[6,"kintones"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /stripe/webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_stripe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stripe"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/customer_managements/hibiya/hibiya_expenses/year(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const year_users_customer_managements_hibiya_hibiya_expenses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"customer_managements"],[2,[7,"/"],[2,[6,"hibiya"],[2,[7,"/"],[2,[6,"hibiya_expenses"],[2,[7,"/"],[2,[6,"year"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

